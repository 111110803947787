import {Button as BootstrapButton, ButtonProps as BootstrapButtonProps} from 'react-bootstrap';
import {MoonLoader} from 'react-spinners';
import {ReactNode} from 'react';

type ButtonProps = {
  children?: ReactNode;
  loading?: boolean;
  className?: string;
  loadingSize?: number;
  loadingColor?: string;
  spanClassName?: string;
} & BootstrapButtonProps;

export default function Button({children, loading, className, loadingSize = 20, loadingColor, spanClassName, ...rest}: ButtonProps) {
  return <BootstrapButton className={`position-relative border-0 ${className}`} {...rest}>
    <span style={{visibility: loading ? 'hidden' : 'inherit'}} className={spanClassName}>{children}</span>
    <div className="position-absolute d-flex justify-content-center align-items-center h-100 w-100" style={{
      top: '0', left: '0',
      visibility: loading ? 'inherit' : 'hidden',
    }}>
      <MoonLoader className="spinner-fix m-auto" color={loadingColor} size={loadingSize}/>
    </div>
  </BootstrapButton>;
}
