import {ReactNode, useRef, useState} from 'react';
import axios from 'axios';
import PostCardComment from './PostCardComment';
import PostCardNewComment from './PostCardNewComment';
import {getRelativeTime} from '../helpers/utils';
import {BsFillMoonStarsFill, BsPatchCheckFill} from 'react-icons/bs';
import {Link, useHistory} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';
import {AlertProps, useAlert} from '../helpers/alert-provider';
import {log} from 'util';
import {BusinessReview} from '../api-types';
import {Rating, RoundedStar} from '@smastrom/react-rating';
import {IoMdBusiness} from 'react-icons/io';

type ReviewCardProps = {
    review: (BusinessReview & {profile: {businessProfile: {verified: boolean} | null, tag: string, name: string, pictureUid: string, verified: boolean}});
}

export default function ReviewCard({review}:ReviewCardProps) {
  const [isOpen, setOpen] = useState(false);
  const [isOptions, setOptions] = useState<boolean>(false);
  const history = useHistory();
  const {user} = useUser();
  const {setAlert} = useAlert();

  const toggleOptions = () => setOptions(!isOptions);

  const [deleted, setDeleted] = useState(false);

  // const {user, time, des, avater, postimage, postvideo, id} = this.props;


  function deletePost() {
    const alert:AlertProps = {
      title: 'Are you sure you want to delete this review?',
      content: 'You are about delete a review, are you sure you want to do this?',
      dismissible: false,
      onConfirm: () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/business/review/${review.id}/`, {withCredentials: true}).then((res) =>
          setDeleted(true),
        );
      },
    };

    setAlert(alert);
  }

  if (deleted) return <></>;

  function getMessage() {
    return review.message || 'Some cool long message'.repeat(20);
  }

  console.log(review);

  function adminDelete() {
    let reason = '';
    let user = true;

    const alert: AlertProps = {
      title: 'Deleting post',
      childNode: <>
        <div className="form-group">
          <label className="mont-font fw-600 font-xsss mb-1 mt-1">Removal Reason</label>
          <input
            type="text" className="form-control" onChange={(e) => reason = e.target.value}/>
          <div className="invalid-feedback text-red font-xsss fw-500 "></div>
          <label className="mont-font fw-600 font-xsss mb-2 mt-2" title="Tick this when this punishment is sent OOCly (e.g. inappropriate content), if this removal is IC, do not tick this.">User Punishment</label>
          <input
            type="checkbox" className="form-check-input" defaultChecked={true} onChange={(e) => user = e.target.checked} style={{marginLeft: 10, marginTop: 12}}/>
          <div className="invalid-feedback text-red font-xsss fw-500 "></div>
        </div>
      </>,
      onConfirm: () => {
        axios.post(`${process.env.REACT_APP_API_URL}/admin/delete/review/${review.id}`, {reason: reason, user: user}, {withCredentials: true})
            .then((res) => setDeleted(true));
      },
    };

    setAlert(alert);
  }


  return (<>
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 post">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3 cursor-pointer" onClick={() => history.push(`/p/${review.profile.tag}`)}><img
          src={`${process.env.REACT_APP_API_URL}/image/${review.profile.pictureUid}/thumb.webp`} alt="avater"
          className="shadow-sm rounded-circle w45"/></figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1 cursor-pointer position-relative" onClick={() => history.push(`/p/${review.profile.tag}`)}> {review.profile.name} <span
          className="font-xssss fw-500 mt-1 lh-3 text-grey-600 cursor-pointer" onClick={() => history.push(`/p/${review.profile.tag}`)}> @{review.profile.tag}</span>
        {review.profile.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
        {review.profile.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
        {review.profile.verified && <span title="The identity of this account is verified" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><BsPatchCheckFill/></span>}
        <span
          className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-600"> {getRelativeTime(new Date(review.date))}</span></h4>
        {((user?.activeProfile != undefined && review.profileId == user.activeProfile.profileId) || user?.staff) &&
            <div className="ms-auto pointer position-relative" onClick={toggleOptions}>
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"/>
              <div className={`dropdown-menu p-2 px-3 rounded-xxl border-0 shadow-lg ${isOptions ? 'show' : ''}`} style={{top: '80%'}}>
                {user?.activeProfile != undefined && review.profileId == user.activeProfile.profileId &&
                    <div className="border-0 card pt-2 bg-transparent-card w-100"><a
                      className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={deletePost}>Remove Post</a></div>
                }
                {user?.staff &&
                    <div className="border-0 card pt-2 bg-transparent-card w-100"><a
                      className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={adminDelete}>Admin Remove Post</a></div>
                }
              </div>
            </div>
        }

      </div>
      <div className="card-body p-0 me-lg-5">
        <Rating
          value={review.rating}
          style={{maxWidth: 180}}
          itemStyles={{
            itemShapes: RoundedStar,
            activeFillColor: '#f6bc03',
            inactiveFillColor: 'rgba(215,215,215)',
          }}
          readOnly
        /> <p className="fw-500 text-grey-900 lh-26 font-xssss w-100 mb-2"> {getMessage()} </p>
      </div>
      <div className="card-body d-flex p-0">

      </div>
    </div></>
  );
}
