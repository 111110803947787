import {ReactNode, useRef, useState} from 'react';
import axios from 'axios';
import PostCardComment from './PostCardComment';
import PostCardNewComment from './PostCardNewComment';
import {getRelativeTime} from '../helpers/utils';
import {BsFillMoonStarsFill, BsPatchCheckFill} from 'react-icons/bs';
import {Link, useHistory} from 'react-router-dom';
import {useUser} from '../helpers/auth-context';
import {AlertProps, useAlert} from '../helpers/alert-provider';
import {log} from 'util';
import {IoMdBusiness} from 'react-icons/io';

type PostCardProps = {
    post: any;
    seeMore?: boolean;
}

export default function PostCard({post, seeMore = true}:PostCardProps) {
  const [isOpen, setOpen] = useState(false);
  const [isOptions, setOptions] = useState<boolean>(false);
  const [isCommentsOpen, setCommentsOpen] = useState(false);
  const [liked, setLiked] = useState(post.likes.length === 1);
  const [likes, setLikes] = useState(post._count.likes);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const history = useHistory();
  const {user} = useUser();
  const {setAlert} = useAlert();

  const toggleOpen = () => setOpen(!isOpen);
  const toggleComments = () => setCommentsOpen(!isCommentsOpen);
  const toggleOptions = () => setOptions(!isOptions);

  function toggleLike() {
    axios.post(`${process.env.REACT_APP_API_URL}/post/like`, {
      action: liked ? 'unlike' : 'like',
      post: post.id,
    }, {
      withCredentials: true,
    }).then((res) => {
      if (res.status === 200) {
        setLikes(likes + (liked ? -1 : 1));
        setLiked(!liked);
      }
    });
  }

  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [comments, setComments] = useState([]);
  const [hasComments, setHasComments] = useState(post._count.comments > 0);
  const [deleted, setDeleted] = useState(false);

  function loadComments() {
    if (loading || !hasMore) return;
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/post/${post.id}/comments?skip=${current}&take=5`, {withCredentials: true})
        .then((res) => {
          setComments(comments.concat(res.data));
          setCurrent(current+5);
          setLoading(false);
          setHasMore(res.data.length === 5);
        });
  }
  // const {user, time, des, avater, postimage, postvideo, id} = this.props;

  const menuClass = `${isOpen ? ' show' : ''}`;
  const commentsClass = isCommentsOpen ? 'show' : '';

  function deletePost() {
    const alert:AlertProps = {
      title: 'Are you sure you want to delete this post?',
      content: 'You are about delete a post, are you sure you want to do this?',
      dismissible: false,
      onConfirm: () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/post/${post.id}/`, {withCredentials: true}).then((res) =>
          setDeleted(true),
        );
      },
    };

    setAlert(alert);
  }

  if (deleted) return <></>;

  function join(a: any[], b:any) {
    const r = [];
    a.forEach((v, i) => {
      if (i!==a.length-1) r.push(v, b);
      else r.push(v);
    });
    return r;
  }

  function getMessage() {
    if (post.mentions == undefined || post.tags == undefined) return post.message;

    let result: ReactNode[] =[post.message];
    for (const tagobj of post.tags) {
      const tag = tagobj.tag;
      const r = new RegExp(`#${tag}`, 'gi');
      const newResult = [];

      result.forEach((v) => {
        if (typeof v == 'string') {
          const parts = (v as string).split(r);
          newResult.push(...join(parts, <Link className="tag" to={`/tag/${tag}`}>#{tag}</Link>));
        } else {
          newResult.push(v);
        }
      });

      result = newResult;
    }

    for (const mentionobj of post.mentions) {
      const mention = mentionobj.tag;
      const r = new RegExp(`@${mention}`, 'gi');
      const newResult = [];

      result.forEach((v) => {
        if (typeof v == 'string') {
          const parts = (v as string).split(r);
          newResult.push(join(parts, <Link className="tag" to={`/p/${mention}`}>@{mention}</Link>));
        } else {
          newResult.push(v);
        }
      });

      result = newResult;
    }
    return result;
  }

  function adminDelete() {
    let reason = '';
    let user = true;

    const alert: AlertProps = {
      title: 'Deleting post',
      childNode: <>
        <div className="form-group">
          <label className="mont-font fw-600 font-xsss mb-1 mt-1">Removal Reason</label>
          <input
            type="text" className="form-control" onChange={(e) => reason = e.target.value}/>
          <div className="invalid-feedback text-red font-xsss fw-500 "></div>
          <label className="mont-font fw-600 font-xsss mb-2 mt-2" title="Tick this when this punishment is sent OOCly (e.g. inappropriate content), if this removal is IC, do not tick this.">User Punishment</label>
          <input
            type="checkbox" className="form-check-input" defaultChecked={true} onChange={(e) => user = e.target.checked} style={{marginLeft: 10, marginTop: 12}}/>
          <div className="invalid-feedback text-red font-xsss fw-500 "></div>
        </div>
      </>,
      onConfirm: () => {
        axios.post(`${process.env.REACT_APP_API_URL}/admin/delete/post/${post.id}`, {reason: reason, user: user}, {withCredentials: true})
            .then((res) => setDeleted(true));
      },
    };

    setAlert(alert);
  }


  return (<>
    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 post">
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3 cursor-pointer" onClick={() => history.push(`/p/${post.postedBy.tag}`)}><img
          src={`${process.env.REACT_APP_API_URL}/image/${post.postedBy.pictureUid}/thumb.webp`} alt="avater"
          className="shadow-sm rounded-circle w45"/></figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1 cursor-pointer position-relative" onClick={() => history.push(`/p/${post.postedBy.tag}`)}> {post.postedBy.name} <span
          className="font-xssss fw-500 mt-1 lh-3 text-grey-600 cursor-pointer" onClick={() => history.push(`/p/${post.postedBy.tag}`)}> @{post.postedBy.tag}</span>
        {post.postedBy.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
        {post.postedBy.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
        {post.postedBy.verified && <span title="The identity of this account is verified" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><BsPatchCheckFill/></span>}
        <span
          className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-600"> {getRelativeTime(new Date(post.date))}</span></h4>
        {((user?.activeProfile != undefined && post.postedById == user.activeProfile.profileId) || user?.staff) &&
            <div className="ms-auto pointer position-relative" onClick={toggleOptions}>
              <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"/>
              <div className={`dropdown-menu p-2 px-3 rounded-xxl border-0 shadow-lg ${isOptions ? 'show' : ''}`} style={{top: '80%'}}>
                {user?.activeProfile != undefined && post.postedById == user.activeProfile.profileId &&
                    <div className="border-0 card pt-2 bg-transparent-card w-100"><a
                      className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={deletePost}>Remove Post</a></div>
                }
                {user?.staff &&
                    <div className="border-0 card pt-2 bg-transparent-card w-100"><a
                      className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={adminDelete}>Admin Remove Post</a></div>
                }
              </div>
            </div>
        }

      </div>
      <div className="card-body p-0 me-lg-5">
        <p className="fw-500 text-grey-900 lh-26 font-xssss w-100 mb-2">{getMessage()} {seeMore && <Link to={`/p/${post.postedBy.tag}/post/${post.id}`}
          className="fw-600 text-primary ms-2">See more</Link>}</p>
      </div>
      {post.images.length > 0 &&
                <div className="card-body d-block p-0 mb-3">
                  <div className="row ps-2 pe-2">
                    <div className="col-sm-12 p-1 image-col">
                      <img
                        id="image" src={`${process.env.REACT_APP_API_URL}/image/${post.images[0].uid}/thumb.webp`}
                        className="rounded-3 w-100 mx-auto image" alt="post"
                        // style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/image/${post.images[0].uid}/thumb)`}}
                      /></div>
                  </div>
                </div>}
      <div className="card-body d-flex p-0">
        <div
          className="like-btn emoji-bttn d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
          onClick={toggleLike}>
          <i
            // data-tip
            // data-for="like-tooltip"
            className={`${liked ? 'feather-thumbs-down' : 'feather-thumbs-up'} text-white bg-primary-gradiant me-1 btn-round-xs font-xss `}
          />
          {likes} {likes === 1 ? 'Like' : 'Likes'}
        </div>
        {/* <ReactTooltip id='like-tooltip' effect='solid' getContent={()=><span>{liked ? 'Unlike' : 'Like'} this post</span>} />*/}
        {/* <div className={`emoji-wrap pointer ${emojiClass}`}>
                    <ul className="emojis list-inline mb-0">
                        <li className="emoji list-inline-item"><i className="em em---1"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-angry"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-anguished"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-astonished"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-blush"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-clap"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-cry"></i></li>
                        <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li>
                    </ul>
                </div>*/}
        <a href="/" onClick={(e) => {
          e.preventDefault();
          toggleComments();
          if (comments.length === 0) loadComments();
        }}
        className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"><i
            className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i><span
            className="d-none-xss">{post._count.comments} Comment{post._count.comments === 1 ? '' : 's'}</span></a>
        <div
          className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss ${menuClass}`}
          id={`dropdownMenu${post.id}`} data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}>
          <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i><span
            className="d-none-xs">Share</span></div>
        <div
          className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0 ${menuClass}`}
          aria-labelledby={`dropdownMenu${post.id}`}>
          {/* <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">Share <i
            className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i></h4>
          <div className="card-body p-0 d-flex">
            <ul className="d-flex align-items-center justify-content-between mt-2">
              <li className="me-1"><span className="btn-round-lg pointer bg-facebook"><i
                className="font-xs ti-facebook text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-twiiter"><i
                className="font-xs ti-twitter-alt text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-linkedin"><i
                className="font-xs ti-linkedin text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-instagram"><i
                className="font-xs ti-instagram text-white"></i></span></li>
              <li><span className="btn-round-lg pointer bg-pinterest"><i
                className="font-xs ti-pinterest text-white"></i></span></li>
            </ul>
          </div>
          <div className="card-body p-0 d-flex">
            <ul className="d-flex align-items-center justify-content-between mt-2">
              <li className="me-1"><span className="btn-round-lg pointer bg-tumblr"><i
                className="font-xs ti-tumblr text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-youtube"><i
                className="font-xs ti-youtube text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-flicker"><i
                className="font-xs ti-flickr text-white"></i></span></li>
              <li className="me-1"><span className="btn-round-lg pointer bg-black"><i
                className="font-xs ti-vimeo-alt text-white"></i></span></li>
              <li><span className="btn-round-lg pointer bg-whatsup"><i
                className="font-xs feather-phone text-white"></i></span></li>
            </ul>
          </div>*/}
          <h4 className="fw-700 font-xssss text-grey-500 d-flex align-items-center mb-3">Copy Link</h4>
          <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
          <input type="text"
            value={`${window.location.origin}/p/${post.postedBy.tag}/post/${post.id}`}
            className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg" style={{minWidth: 300}}
            onClick={() => {
              navigator.clipboard.writeText(`${window.location.origin}/p/${post.postedBy.tag}/post/${post.id}`);
            }}
            disabled/>
        </div>
      </div>
      <div className={`card-body p-0 mt-2 border-top-xs toggleable comments scroll-bar ${commentsClass}`}>
        {hasMore && (<div className="mt-2">
          <a href="/" className="show-more" onClick={(e) => {
            e.preventDefault();
            loadComments();
          }}>Show more...</a>
        </div>)}
        {hasComments ? comments.sort((a, b) => new Date(a.date) > new Date(b.date) ? 1 : -1).map((c) => <PostCardComment key={c.id} comment={c} post={post} />) :
            <div
              className="bor-0 rounded-xxl p-3 pb-2 mx-auto post-comment isOp text-center">
              <BsFillMoonStarsFill className="d-block mx-auto mb-2 text-grey-700 text-dark-gray" size={40}/>
              <span className="fw-700 text-dark-white text-grey-900">It is quiet here...</span><br/>
              <span className="fw-500 text-dark-white text-grey-900">Be the first one to comment!</span>
            </div>}
      </div>
      <div className={`card-body p-0 mt-2 border-top-xs toggleable comments ${commentsClass}`}>
        <PostCardNewComment postId={post.id} onPost={(e) => {
          setComments(comments.concat([e]));
          if (!hasComments) setHasComments(true);
        }}/>
      </div>
    </div></>
  );
}
