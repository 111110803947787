import {useState} from 'react';
import {useUser} from '../helpers/auth-context';
import {MdOutlinePostAdd} from 'react-icons/md';
import axios from 'axios';
import PostCard from './PostCard';
import CreatepostPhotos from './CreatepostPhotos';

export default function CreatePost() {
  const [message, setMessage] = useState('');
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [resultPost, setResultPost] = useState(false);
  const [confirmPhoto, setConfirmPhoto] = useState(false);

  function post() {
    if (loading || message.length < 1) return;
    setLoading(true);
    const data = new FormData();
    if (photos.length > 0) {
      photos.forEach((file) => data.append('images', file));
    }
    data.set('message', message);
    axios.post(`${process.env.REACT_APP_API_URL}/post/new`, data, {withCredentials: true})
        .then((res) => {
          setLoading(false);
          setMessage('');
          setResultPost(res.data);
        });
  }


  const {user} = useUser();

  const pictureUrl = user?.activeProfile?.profile.pictureUid ? `${process.env.REACT_APP_API_URL}/image/${user.activeProfile.profile.pictureUid}/thumb.webp` : `/assets/images/default-user.webp`;

  if (resultPost) {
    resultPost['likes'] = [];
    return <PostCard post={resultPost}/>;
  } else {
    return (<>
      {confirmPhoto && <div className="card card-error w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body p-0">
          <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
            className="btn-round-sm font-xs text-primary feather-alert-octagon me-2 bg-greylight"></i>This is an IC (In Character) Social Media Platform!</a>
        </div>
        <div className="card-body p-0 mt-3 position-relative">
          <span className="text-grey-700 text-dark-gray">Onrain is an In Character social media platform for the world of SchoolRP a part of Roleplay Hub, therefore it is highly discouraged to post OOC (Out Of Character / Real Life) photos of yourself or others. For everyone&apos;s safety please stick with in game screenshots and/or drawings.</span>
        </div>
      </div>}
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="card-body p-0">
          <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
            className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Create
                        Post</a>
        </div>
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <img src={pictureUrl}
              alt="icon"
              className="shadow-sm rounded-circle w30"/>
          </figure>
          <textarea name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
            cols={30} rows={10} placeholder="What's on your mind?"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            disabled={loading}
          ></textarea>
        </div>
        <div className="card-body d-flex p-0 mt-0">
          {/* <a href="#video"
                   className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                    className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a> */}
          <input type="file" name="post-picture" id="createpost-photo"
            className="input-file" onChange={(e) => {
              setPhotos([e.target.files[0]]);
              setConfirmPhoto(true);
            }} accept="image/*"/>
          <label htmlFor="post-picture">
            <a href="/" onClick={(e) => {
              e.preventDefault();
              document.getElementById('createpost-photo').click();
            }}
            className="d-flex align-items-center font-xssss fw-600 ls-1 my-2 text-grey-700 text-dark pe-4"><i
                className="font-md text-icon-button feather-image me-2"></i><span
                className="d-none-xs">Add Photo</span></a>
          </label>
          {/* <a href="#activity"
                   className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i
                    className="font-md text-warning feather-camera me-2"></i><span
                    className="d-none-xs">Feeling/Activity</span></a>*/}

          <div
            className={`btn-round-md bg-lightgrey ms-auto ${(loading || message.length < 1) && 'is-disabled'}`}>
            <a href="/" onClick={(e) => {
              e.preventDefault();
              post();
            }}><MdOutlinePostAdd className="text-grey-900"
                size="30"/></a>
          </div>
        </div>
        {photos.length > 0 &&
                    <div className="card-body p-0"><CreatepostPhotos
                      photos={photos}
                      onRemove={(file) => setPhotos([])}
                    /></div>
        }
      </div>
    </>);
  }
}
