import {Fragment} from 'react';
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';
import ProfilecardTwo from '../components/ProfilecardTwo';
import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import useSWR from 'swr';
import Notfound from './Notfound';
import {useUser} from '../helpers/auth-context';
import PrivateProfileCard from '../components/PrivateProfileCard';
import ProfilePostList from '../components/ProfilePostList';
import RightMessages from '../components/header/RightMessages';
import CreateReview from '../components/CreateReview';
import ProfileReviewsList from '../components/ProfileReviewsList';
import BusinessDetail from '../components/BusinessDetail';

const API_URL = process.env.REACT_APP_API_URL;

export default function ProfilePage() {
  let {tag, category} = useParams<{tag: string, category?:any}>();

  if (!category) category = 'posts';

  const {user} = useUser();

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data} = useSWR(`${API_URL}/profile/${tag}`, fetcher);

  if (!data) return (<Load></Load>);

  const categories = ['posts'];
  if (data.data.business) categories.push('reviews');

  if (!data.data.success && data.data.errno === 12 || !categories.includes(category)) {
    return <Notfound />;
  }

  const isOwn = user && data.data.profile.id === user.activeProfile?.profile.id;
  const canView = data.data.canview;

  return (
    <Fragment>
      <Header/>
      <Leftnav/>
      <RightMessages />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <ProfilecardTwo profile={data.data.profile} own={isOwn} category={category}/>
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-lg-0">
                <Profiledetail profile={data.data.profile} own={isOwn}/>
                {/* <Profilephoto/>
                                <Events/>*/}
                {data.data.business && <BusinessDetail business={data.data.business} />}
              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {isOwn && category === 'posts' &&
                                    <Createpost />
                }
                {category === 'posts' && ( canView ?
                                    <ProfilePostList profile={data.data.profile}/> :
                                    <PrivateProfileCard />)
                }
                {category === 'reviews' &&<>
                  {!isOwn && <CreateReview businessProfile={data.data.profile}/> }
                  <ProfileReviewsList businessId={data.data.profile.id}/>
                </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat/>
      <Appfooter/>

    </Fragment>
  );
}
