import Button from '../Button';
import MessageMemberRow from './MessageMemberRow';
import {useRef, useState} from 'react';
import useSWR from 'swr';
import axios from 'axios';
import MessageGroupInviteRow from './MessageGroupInviteRow';
import {useAlert} from '../../helpers/alert-provider';
import {vars} from '../../index';

export type GroupInvitesTableProps = {
    groupId: string
}

export default function GroupInvitesTable({groupId}: GroupInvitesTableProps) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {setAlert} = useAlert();
  const toggleOpen = () => setOpen(!open);

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };

  const {data, mutate} = useSWR(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/invites`, fetcher);

  const daysRef = useRef<HTMLInputElement>();
  const usagesRef = useRef<HTMLInputElement>();

  function createInvite() {
    if (loading) return;
    setLoading(true);
    const days = daysRef.current.value;
    const usages = usagesRef.current.value;
    axios.post(`${process.env.REACT_APP_API_URL}/messages/group/${groupId}/invite`, {
      expire: days == '' ? undefined : days,
      usages: usages == '' ? undefined : usages,
    }, {withCredentials: true})
        .then((res) => {
          setOpen(false);
          setLoading(false);
          setAlert({
            title: 'Invite Created!',
            childNode: <input style={{width: 310}} value={'https://social.schoolrp.net/messages/invite/' +res.data.code} className="form-control" readOnly/>,
          });
          mutate();
        })
        .catch((res) => {
          setLoading(false);
        });
  }

  function deleteInvite(slug) {
    setAlert({
      title: 'Are you sure?',
      content: `Are you sure you want to delete the invite: ${'https://social.schoolrp.net/messages/invite/'}${slug}`,
      onConfirm: ()=>{
        axios.delete(`${process.env.REACT_APP_API_URL}/messages/invite/${slug}`, {withCredentials: true})
            .then((res) => {
              mutate();
            });
      },
    });
  }

  if (!data) return <></>;
  const invites = data.data;

  return <div className="row form-group">
    <div className="flex-row d-flex position-relative">
      <label className="d-block mont-font fw-600 font-xsss mb-2">
        <span>Group Invites</span>
      </label>
      <><a className="ms-auto d-block fw-600 cursor-pointer" onClick={toggleOpen}>Create new invite</a>
        <div className={`dropdown-menu p-3 right-0 rounded-xxl border-0 shadow-lg ${open ? 'show' : ''}`}
          style={{width: 500}}>
          <div className="form-group">
            <label className="ms-auto mont-font fw-600 font-xsss mb-2">
                            New Invite
            </label>
          </div>
          <label className="ms-auto mont-font fw-600 font-xsss mb-1" htmlFor="expire">
                        Valid for days
          </label>
          <input ref={daysRef} id="expire" className="form-control" type="number" min={1} defaultValue={7}
            placeholder="No expire"/>
          <label className="ms-auto mont-font fw-600 font-xsss mt-2 mb-1" htmlFor="expire">
                        Usages
          </label>
          <input ref={usagesRef} id="usages" className="form-control" type="number" min={1}
            placeholder="No limit"/>
          <div className="mt-2">
            <Button className="w-100 d-block"
              disabled={loading}
              // disabled={(selected.length < 2 && group.type === 'PERSONAL') || selected.length < 1 || creatingGroup}
              loading={loading}
              onClick={createInvite}>
                            Create Invite
            </Button>
          </div>
        </div>
      </>
    </div>

    <div className="scroll-bar" style={{maxHeight: 1000}}>
      <ul className="profile-list" style={{paddingBottom: 90}}>
        {invites.map((invite) => <MessageGroupInviteRow key={invite.slug} {...invite} onDelete={deleteInvite}/>)}
      </ul>
    </div>
  </div>;
}
