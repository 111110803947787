import {useState} from 'react';
import {useUser} from '../helpers/auth-context';
import {MdOutlinePostAdd} from 'react-icons/md';
import axios from 'axios';
import PostCard from './PostCard';
import CreatepostPhotos from './CreatepostPhotos';
import {BusinessReview, Profile} from '../api-types';
import ReviewCard from './ReviewCard';
import {Rating, RoundedStar, Star} from '@smastrom/react-rating';

type CreateReviewProps = {
  businessProfile: Profile;
}

export default function CreateReview({businessProfile}:CreateReviewProps) {
  const [message, setMessage] = useState('');
  const [photos, setPhotos] = useState([]);
  const [rating, setRating] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [resultPost, setResultPost] = useState<(BusinessReview & {profile: {businessProfile: {verified: boolean} | null, tag: string, name: string, pictureUid: string, verified: boolean}}) | null>(null);

  function post() {
    if (loading || message.length < 1 || rating == 0) return;
    setLoading(true);

    axios.post(`${process.env.REACT_APP_API_URL}/business/${businessProfile.id}/review`, {rating, review: message.length > 0 ? message : undefined}, {withCredentials: true})
        .then((res) => {
          setLoading(false);
          setMessage('');
          setResultPost(res.data);
        });
  }


  const {user} = useUser();

  const pictureUrl = user?.activeProfile?.profile.pictureUid ? `${process.env.REACT_APP_API_URL}/image/${user.activeProfile.profile.pictureUid}/thumb.webp` : `/assets/images/default-user.webp`;

  if (resultPost) {
    return <ReviewCard review={resultPost}/>;
  } else {
    return (<>
      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
        <div className="d-flex flex-row">
          <div className="card-body p-0">
            <a href="/" className="font-xssss fw-600 text-grey-900 card-body p-0 d-flex align-items-center"><i
              className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Rate {businessProfile.name}</a>
          </div>
          <div style={{width: 180}} >
            <Rating
              value={rating}
              onChange={setRating}
              style={{maxWidth: 180}}
              isRequired
              itemStyles={{
                itemShapes: RoundedStar,
                activeFillColor: '#f6bc03',
                inactiveFillColor: 'rgba(215,215,215)',
              }}
            />
          </div>
        </div>
        <div className="card-body p-0 mt-3 position-relative">
          <figure className="avatar position-absolute ms-2 mt-1 top-5">
            <img src={pictureUrl}
              alt="icon"
              className="shadow-sm rounded-circle w30"/>
          </figure>
          <textarea name="message"
            className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
            cols={30} rows={10} placeholder="Leave a review!"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            disabled={loading}
          ></textarea>
        </div>
        <div className="card-body d-flex p-0 mt-0">
          <div
            className={`btn-round-md bg-lightgrey ms-auto ${(loading || message.length < 1) && 'is-disabled'}`}>
            <a href="/" onClick={(e) => {
              e.preventDefault();
              post();
            }}><MdOutlinePostAdd className="text-grey-900"
                size="30"/></a>
          </div>
        </div>
        {photos.length > 0 &&
                    <div className="card-body p-0"><CreatepostPhotos
                      photos={photos}
                      onRemove={(file) => setPhotos([])}
                    /></div>
        }
      </div>
    </>);
  }
}
