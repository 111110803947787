import useSWR from 'swr';
import axios from 'axios';
import {getInitials, getRelativeTime} from '../../helpers/utils';
import {Link} from 'react-router-dom';
import {BsPatchCheckFill} from 'react-icons/bs';

export default function TrendingTags() {
  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {data, error} = useSWR(`${process.env.REACT_APP_API_URL}/feeds/trending-tags`, fetcher);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pb-3">
      <div className="card-body d-flex align-items-center p-4">
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">Trending Tags</h4>
      </div>
      {data !== undefined ? data.data.map((tag, index) => (
        <div className="wrap" key={index}>
          <Link to={`/tag/${tag.tag}`} className="card-body d-flex ps-2 pe-2 pb-0 bor-0 p-2 bg-greylight bg-themedark-light ms-3 me-3 rounded-3 mb-2">
            <span className="btn-round-sm bg-primary-gradiant me-2 tag-icon text-white fw-600 position-relative"><span className="position-absolute">#</span></span>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1 position-relative">#{tag.tag}
              <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">{tag._count} posts</span></h4>
          </Link>
        </div>
      )) : <></>}
    </div>
  );
}

