import {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

import Darkbutton from '../components/Darkbutton';
import {useUser} from '../helpers/auth-context';
import Alert from './Alert';
import useSWR from 'swr';
import axios from 'axios';
import Notifications from './header/Notifications';
import SearchResults from './header/SearchResults';

type HeaderPropTypes = {
    ignoreUnverified?: boolean
}

export default function Header({ignoreUnverified: _ignore = false}: HeaderPropTypes) {
  const [isNoti, setNoti] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [settings, setSettings] = useState(false);
  const [search, setSearch] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [ignoreUnverified, setIgnoreUnverified] = useState(_ignore);

  const state = {
    isOpen: isOpen,
    isActive: isActive,
    isNoti: isNoti,
  };

  const toggleOpen = () => setOpen(!isOpen);
  const toggleActive = () => setActive(!isActive);
  const toggleisNoti = () => setNoti(!isNoti);

  useEffect(() => {
    document.querySelector('body').addEventListener('click', (e) => setSearchOpen(false));
  });

  const toggleSettings = (e) => {
    e.preventDefault();
    setSettings(!settings);
  };

  const {user} = useUser();


  const navClass = `${state.isOpen ? ' nav-active' : ''}`;
  const buttonClass = `${state.isOpen ? ' active' : ''}`;
  const searchClass = `${state.isActive ? ' show' : ''}`;

  const activeProfile = user?.activeProfile;

  const pictureUrl = user?.activeProfile ? `${process.env.REACT_APP_API_URL}/image/${user.activeProfile.profile.pictureUid}/thumb.webp` : `https://source.boringavatars.com/beam/500/default?colors=682825,EFDDAE,C4ACA3,BD4B4A,534536&square=true`;

  const fetcher = async (url) => {
    return await axios.get(url, {withCredentials: true, validateStatus: () => true});
  };
  const {
    data: notiResult,
    error: noficiationError,
  } = useSWR(`${process.env.REACT_APP_API_URL}/profiledata/notifications?take=5&unread=true`, fetcher);

  const {data: verificationCount} = useSWR(`${process.env.REACT_APP_API_URL}/admin/verifications/count`, fetcher);

  const notificationData = notiResult?.data;

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className="nav-header bg-white shadow-xs border-0">
      {!ignoreUnverified && user && !user.verified && <Alert dismissible={false}
        title="Account Not Verified"
        content="Your account has not yet been verified, to create a profile you must first verify your account."
        linkText="Verify Account"
        link="/settings/accounts/link"
        smooth={false}
        onDismiss={() => {
          setIgnoreUnverified(true);
        }}
      />}
      <div className="nav-top">
        <Link to="/">
          <img src="/assets/images/REAL_LOGO.svg" alt="logo" style={{height: '50px'}}/><span
            className="ms-2 d-inline-block fredoka-font ls-3 fw-600 text-current font-xxl logo-text mb-0">onrain </span>
        </Link>
        <Link to="/messages" className="mob-menu ms-auto me-2 chat-active-btn"><i
          className="feather-message-circle text-grey-900 font-sm btn-round-md bg-greylight"></i></Link>
        <Darkbutton mobile={true}/>
        {/* <span onClick={toggleActive} className="me-2 menu-search-icon mob-menu"><i*/}
        {/*  className="feather-search text-grey-900 font-sm btn-round-md bg-greylight"></i></span>*/}
        <button onClick={toggleOpen} className={`nav-menu me-0 ms-2 ${buttonClass}`}></button>
      </div>

      <div className="float-left header-search ms-3">
        <div className="form-group mb-0 icon-input position-relative">
          <i className="feather-search font-sm text-grey-400"></i>
          <input type="text" placeholder="Start typing to search.."
            className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w350 text-grey-700 text-dark-gray theme-dark-bg"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSearchOpen(true);
            }}/>
          {searchOpen && <SearchResults query={search} className="position-absolute"/>}
        </div>
      </div>
      <NavLink activeClassName="active" to="/" className="p-2 text-center ms-3 menu-icon center-menu-icon"><i
        className="feather-home font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>
      {user?.activeProfile && <NavLink activeClassName="active" to={`/p/${activeProfile.profile.tag}`}
        className="p-2 text-center ms-0 menu-icon center-menu-icon"><i
          className="feather-user font-lg bg-greylight btn-round-lg theme-dark-bg text-grey-500 "></i></NavLink>}

      {user?.activeProfile ? (<>
        <span className={`p-2 pointer text-center ms-auto menu-icon`} id="dropdownMenu3"
          data-bs-toggle="dropdown" aria-expanded="false"
          onClick={toggleisNoti}>{notificationData?.unreadCount > 0 && <span
            className="dot-count bg-warning"></span>}<i className="feather-bell font-xl text-current"></i></span>
        <Notifications open={isNoti} data={notificationData}/>
        <Link to="/messages" className="p-2 text-center ms-3 menu-icon chat-active-btn"><i
          className="feather-message-square font-xl text-current"></i></Link>
        <Darkbutton/>

        <a href="/" onClick={toggleSettings} className="p-0 ms-3 menu-icon"><img src={pictureUrl} alt="user"
          className="w40 mt--1 rounded-circle"/></a>
        <div className={`dropdown-menu p-0 right-0 rounded-xxl border-0 shadow-lg ${settings && 'show'}`}
          aria-labelledby="dropdownMenu3">
          <div className="card bg-transparent-card w-100 border-0 ps-5 mt-4 mx-4">
            <img src={pictureUrl} alt="user" className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{activeProfile.profile.name}</h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">@{activeProfile.profile.tag}</h6>
          </div>
          <div className="only-border-top-xs pe-4 card pt-2 bg-transparent-card w-100 ps-4">
            <Link to={`/p/${activeProfile.profile.tag}`}
              className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Go to profile page</Link>
          </div>
          <div className="border-0 pe-4 card pt-2 bg-transparent-card w-100 ps-4">
            <Link to="/profile/list" className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Switch
                            Profile</Link>
          </div>
          <div className="border-0 pe-4 card pt-2 bg-transparent-card w-100 ps-4">
            <Link to="/profile/edit" className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Edit
                            Profile</Link>
          </div>
          <div className="only-border-top-xs pe-4 card pt-2 bg-transparent-card w-100 ps-4">
            <Link to={`/settings`}
              className="font-xsss text-grey-900 mb-2 mt-0 fw-700 d-block">Account Settings</Link>
          </div>
          <div className="only-border-top-xs pe-4 card pt-2 bg-transparent-card w-100 ps-4 mb-2">
            <a href="/logout" className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">Logout</a>
          </div>
        </div>
      </>) : (user ?
                <>
                  <Darkbutton className="ms-auto mt-2"/>
                  <Link to="/profile/list"
                    className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w150 text-center lh-20 rounded-xl">Select
                        Profile</Link>
                </> :
                <>
                  <Darkbutton className="ms-auto mt-2"/>
                  <a href="/login"
                    className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Login</a>
                  <a href="/register"
                    className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a>
                </>)
      }

      <nav className={`navigation scroll-bar ${navClass}`}>
        <div className="container ps-0 pe-0">

          <div className="nav-content">

            <div
              className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2 mob-only">
              {/* <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Search</span></div>*/}
              <ul className="mb-1 top-content">
                <div className="form-group mb-0 icon-input position-relative">
                  <i className="feather-search font-sm text-grey-400 position-absolute"
                    style={{top: '25%'}}></i>
                  <input type="text" placeholder="Start typing to search.."
                    className="bg-grey border-0 lh-32 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl w-100 text-grey-700 text-dark-gray theme-dark-bg"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSearchOpen(true);
                    }}/>
                  {searchOpen &&
                                        <SearchResults query={search} className="position-absolute z-index-1"/>}
                </div>
              </ul>
            </div>

            <div
              className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500"><span>Navigation</span></div>
              <ul className="mb-1 top-content">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li><Link to="/" className="nav-content-bttn open-font"><i
                  className="feather-tv btn-round-md bg-primary-gradiant me-3"></i><span>Home Feed</span></Link>
                </li>
                <li><Link to={activeProfile ? `/p/${activeProfile.profile.tag}` : `/profile/list`}
                  className="nav-content-bttn open-font"><i
                    className="feather-user btn-round-md bg-primary-gradiant me-3"></i><span>Your Profile</span></Link>
                </li>
              </ul>
            </div>

            {/* <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2">
              <div className="nav-caption fw-600 font-xssss text-grey-500"><span>More </span>Pages</div>
              <ul className="mb-3"></ul>
            </div>*/}

            <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1">
              <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Account</div>
              <ul className="mb-1">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li><Link to="/notifications" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                  className="font-sm feather-bell me-3 text-grey-500"></i><span>Notifications</span>
                {notificationData?.unreadCount > 0 && <span
                  className="circle-count bg-warning mt-1">{notificationData.unreadCount}</span>}
                </Link>
                </li>
                <li><Link to="/profile/edit" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                  className="font-sm feather-settings me-3 text-grey-500"></i><span>Profile Settings</span></Link>
                </li>
                <li><Link to="/profile/list"
                  className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                    className="font-sm feather-list me-3 text-grey-500"></i><span>Profile List</span></Link>
                </li>
              </ul>
            </div>

            {user?.staff && <div className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss mt-2 pt-3 pb-1">
              <div className="nav-caption fw-600 font-xssss text-grey-500"><span></span> Admin</div>
              <ul className="mb-1">
                <li className="logo d-none d-xl-block d-lg-block"></li>
                <li><Link to="/admin/verifications" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                  className="font-sm feather-settings me-3 text-grey-500"></i><span>Verifications</span>
                {verificationCount?.data > 0 && <span
                  className="circle-count bg-warning mt-1">{verificationCount.data}</span> }
                </Link></li>
                {/* <li><Link to="/defaultsettings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i*/}
                {/*  className="font-sm feather-settings me-3 text-grey-500"></i><span>Users</span>*/}
                {/* </Link></li>*/}
                {/* <li><Link to="/defaultsettings" className="nav-content-bttn open-font h-auto pt-2 pb-2"><i*/}
                {/*  className="font-sm feather-settings me-3 text-grey-500"></i><span>Chats</span>*/}
                {/* </Link></li>*/}
              </ul>
            </div>}

            <div
              className="nav-wrap bg-white bg-transparent-card rounded-xxl shadow-xss pt-3 pb-1 mb-2 mt-2 mob-only">
              <div className="nav-caption fw-600 font-xssss text-grey-500"><span>My Account</span></div>
              <ul className="mb-1">
                {user?.activeProfile ? <>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><a href="/settings"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                      className="font-sm feather-settings me-3 text-grey-500"></i><span>Account Settings</span>
                  </a>
                  </li>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><a href="/logout"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                      className="font-sm feather-log-out me-3 text-grey-500"></i><span>Logout</span>
                  </a>
                  </li>
                </> :
                <>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><a href="/signup"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                      className="font-sm feather-plus-square me-3 text-grey-500"></i><span>Sign up</span>
                  </a>
                  </li>
                  <li className="logo d-none d-xl-block d-lg-block"></li>
                  <li><a href="/login"
                    className="nav-content-bttn open-font h-auto pt-2 pb-2"><i
                      className="font-sm feather-log-in me-3 text-grey-500"></i><span>Log in</span>
                  </a>
                  </li>
                </>}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
