import {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import GeoPattern from 'geopattern';
import {useUser} from '../helpers/auth-context';
import Button from './Button';
import axios from 'axios';
import {BusinessProfile, Profile} from '../api-types';
import {BsPatchCheckFill} from 'react-icons/bs';
import {Dropdown} from 'react-bootstrap';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import {IoMdBusiness} from 'react-icons/io';

type ProfileCardProps = {
  profile: (Profile & {_count: {followedBy: number, following: number, posts: number}, businessProfile?: BusinessProfile})
  business?: any
  own: boolean
  category: 'posts' | 'reviews'
}

const API_URL = process.env.REACT_APP_API_URL;
export default function ProfilecardTwo({profile = {_count: {followedBy: 0, following: 0}} as unknown as (Profile & {_count: {followedBy: number, following: number, posts: number}}), own = true, business, category}: ProfileCardProps) {
  const bannerUrl = profile.bannerUid ? `url("${API_URL}/image/${profile.bannerUid}/thumb.webp")` : GeoPattern.generate(profile.tag).toDataUrl();
  const pictureUrl = `${API_URL}/image/${profile.pictureUid}/thumb.webp`;
  const history = useHistory();

  const {user, reloadUser} = useUser();
  const ownProfile = user?.activeProfile?.profile;
  let isFollowing = false;
  let hasRequested = false;
  let blocked = false;
  if (ownProfile !== undefined) {
    isFollowing = ownProfile.following.some((obj) => obj.tag === profile.tag);
    hasRequested = ownProfile.outgoingRequests.some((obj) => obj.tag === profile.tag);
    blocked = ownProfile.blocked.some((obj) => obj.tag === profile.tag);
  }

  const [loading, setLoading] = useState(false);
  const [followers, setFollowers] = useState(profile._count.followedBy);
  const [more, setMore] = useState(false);
  const toggleMore = () => setMore(!more);

  function handleFollowButton() {
    if (loading) return;
    if (!user) {
      window.location.href = '/login';
    } else if (!user.activeProfile) {
      window.location.href = '/profile/list';
    } else {
      setLoading(true);
      axios.post(`${API_URL}/profile/${profile.tag}/follow`, {action: !isFollowing && !hasRequested ? 'FOLLOW' : 'UNFOLLOW'}, {withCredentials: true})
          .then(async () => {
            await reloadUser();
            setFollowers(followers + (!isFollowing && !hasRequested ? 1 : -1));
            setLoading(false);
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
          });
    }
  }

  console.log(category);

  const [messageLoading, setMessageLoading] = useState(false);
  function handleMessageButton() {
    if (messageLoading) return;
    setMessageLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/messages/group/open`, {type: 'PERSONAL', profile: profile.tag}, {withCredentials: true, validateStatus: () => true})
        .then((res) => {
          setMessageLoading(false);
          if (res.status === 200) {
            history.push(`/messages/${res.data.groupId}`);
          }
        });
  }

  const [blockedLoading, setBlockedLoading] = useState(false);
  function handleBlockButton() {
    if (blockedLoading) return;
    setBlockedLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/profile/${profile.tag}/block`, {}, {withCredentials: true})
        .then(reloadUser)
        .then(() => setBlockedLoading(false));
  }

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
      <div className="card-body position-relative h240 bg-image-cover bg-image-center"
        style={{backgroundImage: bannerUrl}}></div>
      <div className="card-body d-block pt-4 text-lg-center position-relative">

        <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-5 ms-lg-auto me-auto"><img
          src={pictureUrl} alt="avatar" className="p-1 bg-card rounded-xl w-100"/></figure>

        <div className="text-center ms-5 ms-lg-auto me-auto d-lg-none" style={{width: 100}}>
          <h4 className="font-xs ls-1 fw-700 text-grey-900 ms-lg-0">{profile.name}
            {profile.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm text-dark-white text-grey-900" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
            {profile.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
            {profile.verified && <span title="The identity of this account is verified" className="profile-badge text-success font-sm ms-1" style={{lineHeight: '0'}}><BsPatchCheckFill/></span>}
            <span
              className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{profile.tag}</span></h4>
        </div>

        <h4 className="font-xs ls-1 fw-700 text-grey-900 ms-lg-0 d-lg-block d-none">{profile.name}
          {profile.businessProfile?.verified === false && <span title="This is an unverified business profile" className="profile-badge ms-1 font-sm text-dark-white text-grey-900" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
          {profile.businessProfile?.verified === true && <span title="This is a verified store or business within Karakura and can be trusted" className="profile-badge ms-1 text-theme font-sm" style={{lineHeight: '0'}}><IoMdBusiness/></span>}
          {profile.verified && <span title="The identity of this account is verified" className="profile-badge text-success font-sm ms-1" style={{lineHeight: '0'}}><BsPatchCheckFill/></span>}
          <span
            className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">@{profile.tag}</span></h4>

        <div className="d-flex align-items-center pt-0 position-absolute left-15 top-10 mt-4 ms-2">
          <h4 className="font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">{profile._count.posts} </b> Posts</h4>
          <Link to={`/p/${profile.tag}/followers`} className="h4 font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">{followers} </b> Followers
          </Link>
          <Link to={`/p/${profile.tag}/following`} className="h4 font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">{profile._count.following} </b> Following
          </Link>
        </div>
        <div
          className="d-flex flex-column flex-sm-row align-items-lg-center align-items-end justify-content-center position-absolute right-15 top-10 mt-2 me-2 profile-buttons">
          {own ? <Link to="/profile/edit"
            className="d-none d-lg-block bg-greylight btn-round-lg ms-2 rounded-3"><i
              className="feather-edit-3 font-md text-always-gray-900"></i></Link> :
              blocked ?
                <>
                  <Button
                    loading={blockedLoading}
                    className=" bg-dark p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                    onClick={handleBlockButton}>
                    Unblock
                  </Button>
                </> :
                <>
                  <Button
                    loading={loading}
                    className=" bg-success p-3 mb-2 mb-lg-0 z-index-1 rounded-3 text-white text-grey-900 font-xsssss text-uppercase fw-700 ls-3"
                    onClick={handleFollowButton}>
                    {isFollowing ? 'Unfollow' : (hasRequested ? 'Cancel Request': (`${profile.visibility === 'PRIVATE' ? 'Request' : ''} Follow`))}
                  </Button>
                  <div className="d-flex flex-row">
                    <Button loading={messageLoading}
                      onClick={handleMessageButton}
                      className=" bg-greylight mb-2 mb-lg-0 btn-round-lg ms-2 rounded-3"><i
                        className="feather-mail font-md text-always-gray-900" style={{lineHeight: '1.5'}}></i></Button>
                    <Button
                      onClick={toggleMore}
                      loading={blockedLoading}
                      className=" bg-greylight mb-2 mb-lg-0  btn-round-lg ms-2 rounded-3 position-relative"><i
                        className="feather-more-horizontal font-md text-always-gray-900" style={{lineHeight: '1.5'}}></i>
                      <div className={`dropdown-menu p-2 px-3 rounded-xxl border-0 shadow-lg right-0 ${more ? 'show' : ''}`} style={{top: '80%'}}>
                        <div className="border-0 card pt-2 bg-transparent-card w-100"><a
                          className="font-xsss text-danger mb-1 mt-0 fw-700 d-block hover-gray cursor-pointer" onClick={handleBlockButton}>Block</a></div>
                      </div>
                    </Button>
                  </div>
                </>
          }
        </div>

      </div>

      <div className="border-top-xs d-flex d-lg-none align-items-center justify-content-evenly">
        <div className="d-flex align-items-center pt-0 position-relative m-2">
          <h4 className="font-xsssss text-center mb-0 d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 font-sm  fw-700 d-inline-block ls-3 text-dark">{profile._count.posts} </b> Posts</h4>
          <Link to={`/p/${profile.tag}/followers`} className="h4 mb-0 font-xsssss text-center d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 font-sm  fw-700 d-inline-block ls-3 text-dark">{followers} </b> Followers
          </Link>
          <Link to={`/p/${profile.tag}/following`} className="h4 mb-0 font-xsssss text-center d-lg-block text-grey-500 fw-600 ms-2 me-2"><b
            className="text-grey-900 font-sm fw-700 d-inline-block ls-3 text-dark">{profile._count.following} </b> Following
          </Link>
        </div>
      </div>

      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab"
          role="tablist">
          <li className="active list-inline-item me-5"><a
            className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${category === 'posts' ? 'active' : ''}`}
            href={`${window.location.origin}/p/${profile.tag}`} data-toggle="tab">Posts</a></li>
          {profile.businessProfile !== null &&
          <li className="list-inline-item me-5"><a
            className={`fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block ${category === 'reviews' ? 'active' : ''}`}
            href={`${window.location.origin}/p/${profile.tag}/reviews`}
            data-toggle="tab">Reviews</a></li>}
          {/*
                        <li className="list-inline-item me-5"><a
                            className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2"
                            data-toggle="tab">Membership</a></li>
                        <li className="list-inline-item me-5"><a
                        className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3"
                        data-toggle="tab">Discussion</a></li>
                        <li className="list-inline-item me-5"><a
                        className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs4"
                        data-toggle="tab">Video</a></li>
                        <li className="list-inline-item me-5"><a
                        className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs3"
                        data-toggle="tab">Group</a></li>
                        <li className="list-inline-item me-5"><a
                        className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1"
                        data-toggle="tab">Events</a></li>
                        <li className="list-inline-item me-5"><a
                        className="fw-700 me-sm-5 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                        href="#navtabs7" data-toggle="tab">Media</a></li>
                        <li className="list-inline-item ms-auto mt-3 me-4"><a href="/home" className=""><i
                        className="ti-more-alt text-grey-500 font-xs"></i></a></li>
                    */}
        </ul>
      </div>
    </div>

  );
}
