import {getRelativeTime} from '../../helpers/utils';
import {Link} from 'react-router-dom';
import {BsFillGearFill, BsGearFill} from 'react-icons/bs';
import {Post} from '../../api-types';

export type NotificationData = {
    id: number;
    profileId: number;
    date: string;
    read: boolean;
    type: keyof ['NEW_COMMENT', 'NEW_FOLLOWER', 'NEW_FOLLOW_REQUEST', 'SYSTEM'];
    postId?: number | null;
    commentId?: number | null;
    followerId?: number | null;
    message?: string | null;

    comment?: NotificationCommentData | null
    follower?: NotificationProfileData | null
    post?: NotificationPostData | null;
}

export type NotificationPostData = (Post & {postedBy: {tag: string, name: string, pictureUid: string}})

export type NotificationProfileData = {
    tag: string;
    name: string;
    pictureUid: string;
}

export type NotificationCommentData = {
    id: number;
    date: string;
    message: string;
    postedBy: NotificationProfileData;
    post: {id: string; postedBy: {tag: string}}
}

type NotificationProps = {
    notification: NotificationData,
    table?: boolean;
    key?: any
}

export default function Notification({notification, table=false, key} : NotificationProps) {
  if (!table) {
    switch (notification.type) {
      case 'NEW_COMMENT':
        return (
          <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
            <img
              src={`${process.env.REACT_APP_API_URL}/image/${notification.comment.postedBy.pictureUid}/thumb.webp`}
              alt="user" className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{notification.comment.postedBy.name}
              <span
                className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4"><Link
              to={`/p/${notification.comment.postedBy.tag}`}>{notification.comment.postedBy.name}</Link> placed
                            a comment on one of your <Link to={`/p/${notification.comment.post.postedBy.tag}/post/${notification.comment.post.id}`}>posts</Link>! </h6>
          </div>
        );
      case 'POST_MENTION':
        return (
          <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
            <img
              src={`${process.env.REACT_APP_API_URL}/image/${notification.post.postedBy.pictureUid}/thumb.webp`}
              alt="user" className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{notification.post.postedBy.name}
              <span
                className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4"><Link
              to={`/p/${notification.post.postedBy.tag}`}>{notification.post.postedBy.name}</Link> mentioned you in one of their
                        a comment on one of your <Link to={`/p/${notification.post.postedBy.tag}/post/${notification.post.id}`}>posts</Link>! </h6>
          </div>
        );
      case 'NEW_FOLLOWER':
        return (
          <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
            <img
              src={`${process.env.REACT_APP_API_URL}/image/${notification.follower.pictureUid}/thumb.webp`}
              alt="user" className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{notification.follower.name}
              <span
                className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4"><Link
              to={`/p/${notification.follower.tag}`}>{notification.follower.name}</Link> started following
                            your profile! </h6>
          </div>
        );
      case 'NEW_FOLLOW_REQUEST':
        return (
          <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
            <img
              src={`${process.env.REACT_APP_API_URL}/image/${notification.follower.pictureUid}/thumb.webp`}
              alt="user" className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">{notification.follower.name}
              <span
                className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4"><Link
              to={`/p/${notification.follower.tag}`}>{notification.follower.name}</Link> has requested to follow your profile! <Link to="/profile/requests">Review requests</Link> </h6>
          </div>
        );
      case 'SYSTEM':
        return (
          <div className="card bg-transparent-card w-100 border-0 ps-5 mb-3">
            <BsFillGearFill
              className="w40 position-absolute left-0"/>
            <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">System Message
              <span
                className="text-grey-400 font-xsssss fw-600 float-right mt-1">{getRelativeTime(new Date(notification.date))}</span>
            </h5>
            <h6 className="text-grey-500 fw-500 font-xssss lh-4">{notification.message}</h6>
          </div>
        );
    }
  } else {
    const readClass = notification.read ? '' : 'bg-lightblue theme-light-bg';

    switch (notification.type) {
      case 'NEW_COMMENT':
        return (
          <li key={key}>
            <div className={`d-flex align-items-center p-3 rounded-3 ${readClass} mb-1`}>
              <img src={`${process.env.REACT_APP_API_URL}/image/${notification.comment.postedBy.pictureUid}/thumb.webp`} alt="user" className="w45 me-3" />
              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><Link
                to={`/p/${notification.comment.postedBy.tag}`} className="strong">{notification.comment.postedBy.name}</Link> placed
                  a comment on one of your <Link to={`/p/${notification.comment.post.postedBy.tag}/post/${notification.comment.post.id}`}>posts</Link><span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {getRelativeTime(new Date(notification.date))}</span> </h6>
              {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>*/}
            </div>
          </li>
        );
      case 'POST_MENTION':
        return (
          <li key={key}>
            <div className={`d-flex align-items-center p-3 rounded-3 ${readClass} mb-1`}>
              <img src={`${process.env.REACT_APP_API_URL}/image/${notification.post.postedBy.pictureUid}/thumb.webp`} alt="user" className="w45 me-3" />
              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><Link
                to={`/p/${notification.post.postedBy.tag}`} className="strong">{notification.post.postedBy.name}</Link> mentioned you in one of their
              <Link to={`/p/${notification.post.postedBy.tag}/post/${notification.post.id}`}> posts</Link><span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {getRelativeTime(new Date(notification.date))}</span> </h6>
              {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>*/}
            </div>
          </li>
        );
      case 'NEW_FOLLOWER':
        return (
          <li key={key}>
            <div className={`d-flex align-items-center p-3 rounded-3 ${readClass} mb-1`}>
              <img src={`${process.env.REACT_APP_API_URL}/image/${notification.follower.pictureUid}/thumb.webp`} alt="user" className="w45 me-3" />
              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><Link
                to={`/p/${notification.follower.tag}`} className="strong">{notification.follower.name}</Link> started following your profile!<span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {getRelativeTime(new Date(notification.date))}</span> </h6>
              {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>*/}
            </div>
          </li>
        );
      case 'NEW_FOLLOW_REQUEST':
        return (
          <li key={key}>
            <div className={`d-flex align-items-center p-3 rounded-3 ${readClass} mb-1`}>
              <img src={`${process.env.REACT_APP_API_URL}/image/${notification.follower.pictureUid}/thumb.webp`} alt="user" className="w45 me-3" />
              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20"><Link
                to={`/p/${notification.follower.tag}`} className="strong">{notification.follower.name}</Link> has requested to follow your profile! <Link to="/profile/requests">Review requests</Link><span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {getRelativeTime(new Date(notification.date))}</span> </h6>
              {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>*/}
            </div>
          </li>
        );
      case 'SYSTEM':
        return (
          <li key={key}>
            <div className={`d-flex align-items-center p-3 rounded-3 ${readClass} mb-1`}>
              <BsGearFill className="w45 me-3" />
              <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">{notification.message}<span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto"> {getRelativeTime(new Date(notification.date))}</span> </h6>
              {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i>*/}
            </div>
          </li>
        );
    }
  }
}
